// @ts-strict-ignore
const useGetBrowserNameVer = () => {
  function get_browser() {
    const ua = navigator.userAgent
    let tem
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return { name: 'IE', version: tem[1] || '' }
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/)
      if (tem != null) {
        return { name: 'Opera', version: tem[1] }
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1])
    }
    return {
      name: M[0],
      version: M[1],
    }
  }
  const { name, version } = get_browser()

  const isBrowserES2016 = () => {
    const latestBrowserVer = {
      Edge: 85,
      Firefox: 80,
      Chrome: 85,
      Safari: 14.7,
      'Android browser': 92,
      'Chrome for Android': 92,
      IE: false,
      etc: false,
    }
    /**
     * 1. 쿠키에 chrome_download_banner가 noshow(X 버튼 클릭한 경우)
     * 2. 크롬 외 타 브라우저 && es2021 지원시 노출
     */
    const browserName = Object.keys(latestBrowserVer).find((brn) => name.indexOf(brn) > -1) || 'etc'
    // 위 브라우저 종류에 없으면 etc로 처리
    return latestBrowserVer[browserName] ? latestBrowserVer[browserName] <= version : latestBrowserVer[browserName]
  }

  return { name, version, isBrowserES2016 }
}

export default useGetBrowserNameVer
