// @ts-strict-ignore
import { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { s_common } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { RootState } from '../../reducer/rootReducer'
import { searchBarActions } from '../../reducer/searchBarReducer'
import CenterContainer from './CenterContainer'

const SearchBar = ({ onSubmit }: { onSubmit: any }) => {
  const [focus, setFocus] = useState(false)

  const dispatch = useDispatch()
  const handleInputFocus = () => {
    setFocus(true)
    dispatch(searchBarActions.show())
  }

  const setKeyword = (value) => {
    dispatch(searchBarActions.setKeyword(value))
  }

  const cancelSearch = (e) => {
    e.preventDefault()
    dispatch(searchBarActions.close())
    dispatch(searchBarActions.setHideResult())
    dispatch(searchBarActions.setKeyword(''))
  }

  const { isOpen, keyword, placeholder } = useSelector(
    (state: RootState) => ({
      isOpen: state.searchBar.isOpen,
      keyword: state.searchBar.tutorPayload.search,
      placeholder: state.searchBar.placeholder,
    }),
    shallowEqual
  )

  const { t } = useTranslation()

  return (
    <CenterContainer clx="max-w-full mx-auto" cond={isOpen && 'pt-32 pb-96'}>
      <form onSubmit={onSubmit} className="max-w-1040 mx-auto">
        <div className="flex items-center">
          <div className="text-sBody flex w-full flex-row rounded-full border py-[8px] px-[16px] text-black">
            {/* 아이콘 */}
            <div>
              <span className="border-0 pl-4">
                <i className="fe fe-search text-black"></i>
              </span>
            </div>

            {/* input */}
            <div className="w-full">
              <input
                type="text"
                value={keyword}
                onChange={(event) => setKeyword(event.target.value)}
                className="text-sBody w-full border-0 bg-white px-16 py-0 placeholder-gray-300 focus:outline-none focus:ring-0 focus:ring-transparent"
                placeholder={placeholder}
                onFocus={(e) => handleInputFocus()}
                onBlur={(e) => setFocus(false)}
                autoFocus={isOpen}
              />
            </div>
            {isOpen && keyword != null && keyword != undefined && keyword != '' && (
              <div
                onClick={() => setKeyword('')}
                className="flex h-20 w-20 cursor-pointer items-center justify-center rounded-full border border-black"
              >
                <i className="fe fe-x text-h6 text-black"></i>
              </div>
            )}
          </div>
          {isOpen && (
            <div
              onClick={(e) => cancelSearch(e)}
              className="text-sBody col col-auto ml-16 cursor-pointer text-gray-700"
            >
              {t(s_common.cancel)}
            </div>
          )}
        </div>
      </form>
    </CenterContainer>
  )
}

export default SearchBar
