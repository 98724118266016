import VWContainer from './VWContainer'

const TutorFooter = () => {
  return (
    <VWContainer clx="bg-gray-50 py-72">
      <div className="w-full text-center">
        <img src={'/assets/img/logo/logo1.png'} width={78} className="mx-auto" />
        <div className="text-h1 mt-[14px] font-bold text-purple-500">App for Ringle Tutors</div>
        <div className="mt-[8px] font-medium text-gray-500">
          Scan the QR Code to download the latest App for Ringle Tutors!
        </div>
        <div className="mx-auto mt-[72px] flex max-w-[560px] justify-between">
          <div>
            <div className="flex bg-white p-[16px]">
              <img src={'/assets/img/logo/qrcode_android_.svg'} className="h-[82px] w-[82px]" />
            </div>
            <div className="text-h4 mt-[22px] font-medium text-gray-500">For Android</div>
          </div>
          <div>
            <div className="flex bg-white p-[16px]">
              <img src={'/assets/img/logo/qrcode_ios_.svg'} className="h-[82px] w-[82px]" />
            </div>
            <div className="text-h4 mt-[22px] font-medium text-gray-500">For iOS</div>
          </div>
        </div>
      </div>
    </VWContainer>
  )
}

export default TutorFooter
