import classNames from 'classnames'

interface AvatarImgProps {
  src: string
  xs?: boolean
  sm?: boolean
  lg?: boolean
  xl?: boolean
  xxl?: boolean
  size?: number
  className?: string
  imgClass?: string
}

const AvatarImg = ({ src, xs, sm, lg, xl, xxl, size, className, imgClass }: AvatarImgProps) => {
  return (
    <div
      style={{ width: size, height: size }}
      className={classNames(
        [
          'avatar',
          {
            'avatar-sm': sm,
            'avatar-lg': lg,
            'avatar-xs': xs,
            'avatar-xl': xl,
            'avatar-xxl': xxl,
          },
          className,
        ],
        'boxBorder relative rounded-full'
      )}
    >
      <img
        src={src}
        data-toggle={'tooltip'}
        onContextMenu={(e) => e.preventDefault()}
        className={classNames(['avatar-img rounded-full', imgClass])}
      />
      <div className="block-copy-bg"></div>
    </div>
  )
}

export default AvatarImg
