// @ts-strict-ignore
import React from 'react'
import Div from '../../components/basic/Div'
import { s_common } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { DivPropType } from '../basic/DivPropsType'
interface BadgeType extends DivPropType {
  black?: boolean
  forty?: boolean
  twenty?: boolean
  primary?: boolean
  disabled?: boolean
  outlined?: boolean
  disabledOutlined?: boolean
  dangerLight?: boolean
  blackLight?: boolean
  fortyLight?: boolean
  twentyLight?: boolean
  primaryLight?: boolean
  secondaryLight?: boolean
  disabledLight?: boolean
  danger?: boolean
  warning?: boolean
  warningLight?: boolean
  dangerOutlined?: boolean
  clx?: any
  style?: any
  duration?: boolean
  chip?: boolean
  free?: any
  dataRef?: string
}

const Badge: React.FC<BadgeType> = ({
  black,
  forty,
  twenty,
  primary,
  outlined,
  disabled,
  disabledOutlined,
  dangerLight,
  secondaryLight,
  blackLight,
  fortyLight,
  twentyLight,
  primaryLight,
  danger,
  warning,
  dangerOutlined,
  clx,
  children,
  style,
  duration,
  disabledLight,
  warningLight,
  free,
  chip,
  dataRef,
  ...others
}) => {
  const { t } = useTranslation()

  return (
    <Div
      textH6
      roundedMd={!chip}
      roundedFull={chip}
      px={chip ? 12 : 8}
      py={chip ? 3 : 0}
      inlineBlock
      {...others}
      style={style}
      cond={[
        outlined && 'bg-white text-purple-500 border border-purple-500',
        primary && 'bg-purple-500 text-white',
        black && 'text-white bg-black',
        forty && 'text-white bg-green-500',
        twenty && 'text-white bg-blue-500',
        free && 'text-gray-500 bg-gray-100',
        primary && 'text-white bg-purple-500',
        disabled && 'text-gray-700 bg-gray-100',
        disabledOutlined && 'border border-gray-500 text-gray-500 bg-white',
        disabledLight && 'text-gray-300 bg-gray-200 border-gray-200',
        dangerLight && 'text-red-500 bg-red-50',
        secondaryLight && 'text-purple-500 bg-purple-500 bg-opacity-20',
        blackLight && 'text-gray-700 bg-gray-100',
        fortyLight && 'text-green-500 bg-green-50',
        twentyLight && 'text-blue-500 bg-blue-50',
        primaryLight && 'text-purple-500 bg-purple-50',
        warning && 'text-white bg-yellow-500',
        warningLight && 'text-yellow-500 bg-yellow-50',
        danger && 'text-white bg-red-500',
        dangerOutlined && 'border border-red-500 bg-white text-red-500',
      ]}
      clx={clx}
      dataRef={dataRef}
    >
      {children}
      {duration && t(s_common.minutesNoDash)}
    </Div>
  )
}

export default Badge
