import { useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { tutor_apis } from '../../apis'
import { notificationCountQueryApi } from '../../react-query/queryApis'
import { createAxiosTokenConfig } from '../axios-util'

export const useAnnouncement = <T>(token: string) => {
  // FIXME: react query convention
  return useQuery<T>({
    queryKey: [tutor_apis.notifications.announcement()],
    queryFn: async () => {
      const { data } = await axios.get(tutor_apis.notifications.announcement(), createAxiosTokenConfig(token))
      console.log('QUERY RESPONSE :: ', data)
      return data
    },
  })
}

export const useNotification = <T>(token: string, part: string) => {
  // FIXME: react query convention
  return useQuery<T>({
    queryKey: [tutor_apis.notifications.notification(part)],
    queryFn: async () => {
      const { data } = await axios.get(tutor_apis.notifications.notification(part), createAxiosTokenConfig(token))
      console.log('QUERY RESPONSE ::', part, data)
      return data
    },
  })
}

interface CountInfoType {
  new: number
  all: number
}

export const useNotificationCount = () => {
  return useQuery<CountInfoType, AxiosError>({
    queryKey: notificationCountQueryApi.getQueryKey(),
    queryFn: notificationCountQueryApi.getQueryFn(),
  })
}
