// @ts-strict-ignore
import { Menu } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { Slide, ToastContainer, toast as toastType } from 'react-toastify'
import Col from '../../components/basic/Col'
import { useBasicPopup } from '../../components/basic/popup/BasicPopup'
import AvatarImg from '../../components/common/AvatarImg'
import Badge from '../../components/common/Badge'
import CsrOnlyTutorBrazePixel from '../../components/common/CsrOnlyTutorBrazePixel'
import { FeArrowDown, FeArrowUp } from '../../components/common/Icons'
import NoSSR from '../../components/common/NoSSR'
import SearchBar from '../../components/common/SearchBar'
import Stars from '../../components/tutor/Stars'
import { CurrentUser } from '../../model/Common/common.interface'
import { tutor_apis } from '../../modules/apis'
import { useNotificationCount } from '../../modules/apis/tutor/notification'
import { useLogoutTutor } from '../../modules/auth'
import { axiosPost } from '../../modules/axiosHelper'
import { useCurrentUser } from '../../modules/context/UserContext'
import { COOKIE_DOMAIN } from '../../modules/envVars'
import useGetBrowserNameVer from '../../modules/hooks/common/useGetBrowserNameVer'
import useIsAdminLogin from '../../modules/hooks/common/useIsAdminLogin'
import useIsMobile from '../../modules/hooks/common/useIsMobile'
import LinkLocale from '../../modules/i18n/LinkLocale'
import { s_menu } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { DATA_REF_KEY } from '../../modules/ids/e2e/student'
import { RINGLE_LOGO_ROUND } from '../../modules/images'
import {
  ICON_BELL_INACTIVE,
  ICON_CHEVRON_RIGHT_GRAY,
  ICON_RED_ALERT_TRIANGLE,
  ICON_STAR_WHITE,
  ICON_X_GRAY,
} from '../../modules/svg/svg'
import urls from '../../modules/urls'
import { TUTOR_STATUS } from '../../modules/vars'
import { RootState } from '../../reducer/rootReducer'
import { searchBarActions } from '../../reducer/searchBarReducer'
import DateComponent from '../DateComponent'
import Button from '../_legacy/Button'
import { useSidePopup } from '../basic/popup/SidePopup'
import Span from '../common/Span'
import TutorFooter from '../common/TutorFooter'
import AdminUserBadge from '../common/badge/AdminUserBadge'
import TailwindWrapper from '../container/TailwindWrapper'
import TutorRootContainer from './TutorRootContainer'
import NotificationPopupBody from './portal/lesson/NotificationPopupBody'
// 메인 메뉴

type NotiInboxPropsType = {
  handleAlarmClick: any
}

const NotiInbox = ({ handleAlarmClick }: NotiInboxPropsType) => {
  const { data } = useNotificationCount()

  return (
    <div className="relative ml-[20px] flex cursor-pointer items-end">
      <Menu as="nav" className="relative">
        <Menu.Button>
          <a id="alarm" className="mt-[4px] flex items-end" onClick={handleAlarmClick}>
            <img className="w-[25px]" src={ICON_BELL_INACTIVE} />
            {data?.new > 0 && (
              <div className="absolute top-[4px] left-[18px] h-[6px] w-[6px] rounded-full bg-red-500"></div>
            )}
          </a>
        </Menu.Button>
      </Menu>
    </div>
  )
}

interface newNotificationDataType {
  id: number
  receiver_id: number
  content: string
  title: string
  category: string
  tags: string
  on_click_url: string
  updated_at: string
  map: any
  length: number
  has_read: boolean
}
type MainNavPropsType = {
  currentUser?: CurrentUser
  navId: any
  activeId?: any
  data: any
  userNavs: any
  accountNavs: any
}

const MainNav = ({ currentUser = null, navId, activeId = null, data, userNavs, accountNavs }: MainNavPropsType) => {
  const { t } = useTranslation()
  const criteria = 680
  const basicPopup = useBasicPopup()
  const [show, setShow] = useState(window.innerWidth > criteria)
  const [width, setWidth] = useState(window.innerWidth)
  const isAdminLogin = useIsAdminLogin()

  // 메뉴 리스트
  const menus = data.mainNav[navId] ? data.mainNav[navId].filter((e) => !e.none) : []
  // 영문서 교정 튜터에게만 writing 메뉴 노출
  let midMenus = data.topNav.filter((e) => !e.none)
  if (currentUser && !currentUser.writing_task_accessible) {
    midMenus = midMenus.filter((item) => item.id !== 'writing')
  }

  useEffect(() => {
    console.log('CurrentUser:', currentUser)
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setShow(width > criteria)
  }, [width])

  const [showEvent, setShowEvent] = useState(true)
  const [cookies, setCookies] = useCookies(['tutor_event_close'])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShowEvent(!cookies.tutor_event_close)
    }
  })
  const sidePopup = useSidePopup()

  const handleAlarmClick = () => {
    sidePopup.show({
      id: 'noti-popup',
      overlayClose: true,
      border: true,
      bgWhite: true,
      maxW: 405,
      hFull: true,
      noTopSide: true,
      onClose: async () => {
        const res = await axiosPost(tutor_apis.notifications.read())
        console.log(res, 'res')
      },
      body: <NotificationPopupBody />,
    })
  }

  const showScheduleAlert = (e) => {
    e.preventDefault()

    const content =
      currentUser && currentUser.tutor_status_id == TUTOR_STATUS.DORMANT ? (
        <>
          You cannot schedule your lessons since your account has become dormant after more than 90 days of inactivity.
          Please contact the Ringle Team (tutor@ringleplus.com) if you want to reactivate your account. Thank you!
        </>
      ) : (
        <>
          As you have received the maximum number of strikes, you are no longer able to schedule lessons now. Please
          submit an appeal to reactivate your account through the Chatbot. We will review your account and process your
          request as soon as possible. Thank you.
        </>
      )
    basicPopup.show({
      id: 'schedule-alert',
      title: 'Cannot Open Schedule',
      maxW: 600,
      body: (
        <>
          <div>{content}</div>
          <div className="mt-[40px] text-right">
            <Button primary md onClick={() => basicPopup.close('schedule-alert')}>
              OK
            </Button>
          </div>
        </>
      ),
    })
  }

  return (
    <Fragment>
      <nav className="lg:max-w-1040 mx-auto px-20">
        <div
          id="navbarCollapse"
          className="row no-gutters relative flex h-[70px] flex-row items-center justify-between lg:justify-center"
        >
          <Menu>
            {() => (
              <>
                <Col clx="flex items-center" auto px0>
                  {/* 로고 */}
                  <LinkLocale href={urls.tutor.portal.home}>
                    <img src={RINGLE_LOGO_ROUND} className="h-[32px]" />
                  </LinkLocale>
                  <div className="ml-[32px] hidden font-medium text-gray-700 sm:block lg:hidden">
                    {menus.filter((e) => !e.none).map((item) => (item.id == activeId ? item.label : ''))}
                  </div>
                </Col>
                <Col auto clx="lg:hidden">
                  <Menu.Button className="items-center rounded-md text-gray-700 focus:outline-none">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-24 w-24" aria-hidden="true" />
                  </Menu.Button>
                  <Menu.Items className="relative">
                    {/*태블릿 뷰포트 메뉴 드롭다운 */}
                    <Menu.Item>
                      <TabletMenu leftMenus={menus} rightMenus={userNavs} />
                    </Menu.Item>
                  </Menu.Items>
                </Col>
              </>
            )}
          </Menu>

          {/* 왼쪽 메뉴 */}
          {
            <div className="col-6 lgd:hidden ml-[32px] flex h-[70px] items-center justify-start px-0">
              <ul className="flex flex-row gap-[32px]">
                {menus.map((item, i) => (
                  <Fragment key={i}>
                    <LinkLocale
                      dataRef={item.dataRef}
                      onClick={!currentUser?.allowed_schedule && item.id == 'schedule' && showScheduleAlert}
                      href={item.href}
                      className={classNames(
                        'nav-link relative font-bold hover:text-purple-500',
                        {
                          'text-purple-500': activeId == item.id,
                        },
                        { 'text-gray-700': activeId != item.id }
                      )}
                    >
                      <span>{item.label}</span>
                    </LinkLocale>
                  </Fragment>
                ))}
              </ul>
            </div>
          }

          <Col h70 clx="flex justify-end items-center px-0 lgd:hidden">
            <ul className="flex flex-row items-center">
              {/*Writing / Event / Referral / FAQ */}
              <MidMenuList data={midMenus} />

              {/* Alarm */}
              <NotiInbox handleAlarmClick={handleAlarmClick} />

              {/* DROPDOWN MENU(PROFILE) */}
              <div className="ml-[20px]">
                <Menu as="nav" className="border-10 z-9999 relative border-red-500">
                  {({ open }) => (
                    <>
                      <Menu.Button className="flex items-center focus:outline-none">
                        <a className="dropdown-toggle mobile-hidden relative flex cursor-pointer items-center justify-center">
                          <AvatarImg
                            size={30}
                            src={currentUser?.image_url}
                            imgClass={
                              activeId == 'menu_mypage'
                                ? 'selected-border'
                                : 'border-purple-500 bg-white selected-border'
                            }
                            className="mr-[8px]"
                          />
                          <div
                            style={{ maxWidth: '60px' }}
                            className={classNames(
                              open ? 'text-purple-500' : 'text-gray-700',
                              'text-truncate text-h5 username truncate font-medium'
                            )}
                          >
                            {`${
                              currentUser?.profile?.korean_name || currentUser?.profile?.name || currentUser?.name
                            }${t(s_menu.sir)}`}
                          </div>
                          {open ? (
                            <FeArrowUp size={12} className="ml-4 text-purple-500" />
                          ) : (
                            <FeArrowDown size={12} className="ml-4 text-black" />
                          )}
                        </a>
                      </Menu.Button>

                      <Menu.Items>
                        <Menu.Item>
                          <li
                            className="nav-item dropdown z-9999 absolute flex items-center"
                            style={{ top: 60, left: -135 }}
                          >
                            <div
                              style={{
                                width: 235,
                                maxHeight: 'calc(100vh - 140px)',
                                overflow: 'auto',
                                boxShadow: '0px 1px 3px 0 rgba(22, 28, 45, 0.12)',
                              }}
                              className="py-lg-4 rounded-lg border border-gray-200 bg-white text-left"
                            >
                              <RingleMenuBody userNavs={userNavs} />
                            </div>
                          </li>
                        </Menu.Item>
                      </Menu.Items>
                    </>
                  )}
                </Menu>
              </div>
            </ul>
          </Col>
        </div>
      </nav>
    </Fragment>
  )
}

type SubNavPropsType = {
  id: any
  activeId: any
  data: any
  subNavId: any
  currentUser: CurrentUser
}

const SubNav = ({ currentUser, id, activeId, data, subNavId }: SubNavPropsType) => {
  const nav = data.subNavs.find((v) => v.id == id)

  const menus = nav?.menus.filter((item) => !item.none) || []

  const isOpen = useSelector((state: RootState) => state.searchBar.isOpen)
  if (!nav) {
    return <Fragment />
  }
  return (
    <div className={classNames({ 'border-b': isOpen }, 'nav-sub border-t bg-white')}>
      <div className="lg:max-w-1040 mx-auto">
        <div>
          <div className="row">
            <Col flex h={50}>
              {menus?.map((item, i) => (
                <Fragment key={`${id}-${i}`}>
                  <LinkLocale href={item.href}>
                    <div
                      data-ref={item?.dataRef}
                      className={classNames(
                        classNames(
                          item.id === activeId ? 'border-b-3 border-black font-medium text-gray-700' : 'text-gray-500'
                        ),
                        'mx-[16px] h-full cursor-pointer py-[16px] pt-[12px] pb-[4px]'
                      )}
                    >
                      {item.label}
                    </div>
                  </LinkLocale>
                </Fragment>
              ))}
            </Col>
          </div>
        </div>
      </div>
    </div>
  )
}

type NewTutorLayoutContainerPropsType = {
  noMainNav?: any
  mainNavId?: string
  mainNavActiveId?: any
  noSubNav?: any
  subNavId?: any
  subNavActiveId?: any
  newTheme?: any
  noContainer?: any
  className?: any
  noTalk?: any
  noBanner?: any
  toast?: any
  ogData?: any
  noTopNav?: any
  onSearchSubmit?: any
  footer?: any
  children?: any
  noChromeBanner?: boolean
}

const NewTutorLayoutContainer = ({
  noMainNav,
  mainNavId = 'main',
  mainNavActiveId,
  noSubNav,
  subNavId,
  subNavActiveId,
  newTheme,
  noContainer,
  className,
  noTalk,
  toast,
  ogData,
  onSearchSubmit,
  footer,
  children,
  noChromeBanner = false,
}: NewTutorLayoutContainerPropsType) => {
  const { currentUser } = useCurrentUser()
  const isAdminLogin = useIsAdminLogin()
  const [height, setHeight] = useState(70)
  const topRef = useRef(null)
  const isMobile = useIsMobile()
  const isOpen = useSelector((state: RootState) => state.searchBar.isOpen)
  const dispatch = useDispatch()
  const closeSearchBar = () => {
    dispatch(searchBarActions.close())
  }
  const { t } = useTranslation()
  const userNavs = [
    {
      id: 'basic-info',
      label: 'Account',
      href: urls.tutor.portal.mypage.basic,
      floor: 1,
    },
    {
      id: 'profile',
      label: 'Profile',
      href: currentUser?.teens_only
        ? urls.tutor.portal.mypage.teensProfile.photo
        : urls.tutor.portal.mypage.profile.photo,
      floor: 1,
    },
    {
      id: 'blog',
      label: 'Blog',
      href: urls.tutor.portal.blog.index,
      floor: 2,
    },
    {
      id: 'notice',
      label: 'Announcement',
      href: urls.tutor.portal.notice.index,
      floor: 2,
    },
  ]
  const accountNavs = [
    {
      id: 'basic-info',
      label: 'Account',
      href: urls.tutor.portal.mypage.basic,
      floor: 1,
      accountMenu: true,
    },
    {
      id: 'notification-settings',
      href: urls.tutor.portal.mypage.noti,
      label: 'Notification',
      floor: 1,
      accountMenu: true,
    },
    {
      id: 'payout',
      label: 'Payouts & Taxes',
      href: urls.tutor.portal.mypage.taxpayerInform,
      floor: 1,
    },
  ]
  const getData = (isTeens, lessonCount, isTeensOnly) => {
    return {
      topNav: [
        {
          id: 'writing',
          label: 'Writing',
          href: urls.tutor.portal.writing.requested,
        },
        {
          id: 'referral',
          label: 'Referral',
          href: urls.tutor.portal.referral.index,
        },
        {
          id: 'faq',
          label: 'FAQ',
          href: urls.tutor.portal.help.index,
        },
      ],
      mainNav: {
        main: [
          {
            id: 'home',
            label: 'Home',
            href: urls.tutor.portal.home,
          },
          {
            id: 'lessons',
            label: 'Lessons',
            href: urls.tutor.portal.lessons.upcomingLessons,
            dataRef: DATA_REF_KEY.btn_tab_lessons,
          },
          {
            id: 'schedule',
            label: 'Schedule',
            href: urls.tutor.portal.schedule.index,
            dataRef: DATA_REF_KEY.btn_tab_schedule,
          },
          {
            id: 'resources',
            label: 'Resources',
            href: urls.tutor.portal.resources.tutoringResources.index,
          },
          {
            id: 'performance-main',
            label: 'Performance',
            href: urls.tutor.portal.performance.status,
          },
        ],
      },
      subNavs: [
        {
          id: 'home',
        },
        {
          id: 'lessons',
          menus: [
            {
              id: 'upcoming',
              label: `Upcoming Lessons (${lessonCount ? lessonCount : 0})`,
              href: urls.tutor.portal.lessons.upcomingLessons,
            },
            {
              id: 'past',
              label: `Past Lessons`,
              href: urls.tutor.portal.lessons.pastLessons,
              dataRef: DATA_REF_KEY.btn_tab_past,
            },
            {
              id: 'lessons',
              label: `Lesson Materials`,
              href: urls.tutor.portal.lessons.lessonMaterials.index,
            },
          ],
        },
        {
          id: 'schedule',
          menus: [
            {
              id: 'main',
              label: 'Main',
              href: urls.tutor.portal.schedule.index,
            },
          ],
        },
        {
          id: 'performance',
          menus: [
            {
              id: 'performance-sub',
              label: 'Performance',
              href: urls.tutor.portal.performance.status,
            },
            {
              id: 'promotion',
              label: <>Promotion</>,
              href: urls.tutor.portal.promotion.status,
              none: isTeensOnly,
            },
            {
              id: 'promotion-teens',
              label: (
                <div className="flex items-center">
                  Promotion{' '}
                  <Badge relative top1 ml2 clx="bg-teens-light text-teens">
                    Teens
                  </Badge>
                </div>
              ),
              href: urls.tutor.portal.promotionTeens.status, //수정 필요
              none: !isTeens,
            },
            {
              id: 'payment',
              label: 'Payment',
              href: urls.tutor.portal.payment.overview,
            },
          ],
        },
        {
          id: 'promotion',
          menus: [
            {
              id: 'status',
              label: <>Status</>,
              href: urls.tutor.portal.promotion.status,
            },
            {
              id: 'level',
              label: <>Level</>,
              href: urls.tutor.portal.promotion.level,
            },
            {
              id: 'log',
              label: <>Promotion Log</>,
              href: urls.tutor.portal.promotion.log,
            },
          ],
        },
        {
          id: 'payment',
          menus: [
            {
              id: 'pay',
              label: 'Upcoming Pay',
              href: urls.tutor.portal.payment.upcomingPay,
            },
            {
              id: 'payout',
              label: 'Payout',
              href: urls.tutor.portal.payment.payout,
            },
            {
              id: 'log',
              label: 'Payment Log',
              href: urls.tutor.portal.payment.log,
            },
            {
              id: 'trancaction-history',
              label: 'Transaction History',
              href: urls.tutor.portal.payment.transactionHistory,
            },
          ],
        },
        {
          id: 'writing',
          menus: [
            {
              id: 'requested',
              label: 'Requested Works',
              href: urls.tutor.portal.writing.requested,
            },
            {
              id: 'completed',
              label: 'Completed Works',
              href: urls.tutor.portal.writing.completed,
            },
          ],
        },
        {
          id: 'resources',
          menus: [
            {
              id: 'tutoring-resources',
              label: 'Ringle Lesson Criteria',
              href: urls.tutor.portal.resources.tutoringResources.index,
            },
            {
              id: 'tutorials-webinars',
              label: 'Ringle 101',
              href: urls.tutor.portal.resources.tutorialsWebinars.index,
            },
          ],
        },
        {
          id: 'my-page',
          menus: [...accountNavs],
        },
        {
          id: 'profile',
          menus: [
            {
              id: 'profile',
              label: 'Profile',
              href: urls.tutor.portal.mypage.profile.photo,
              floor: 1,
              none: isTeensOnly,
            },
            {
              id: 'profile-teens',
              label: (
                <div className="relative flex items-center">
                  Profile{' '}
                  <Badge relative top1 ml2 clx="bg-teens-light text-teens">
                    Teens
                  </Badge>
                </div>
              ),
              href: urls.tutor.portal.mypage.teensProfile.photo,
              floor: 1,
              none: !isTeens,
            },
          ],
        },
      ],
    }
  }

  return (
    <TutorRootContainer ogData={ogData} currentUser={currentUser}>
      {toast && (
        <ToastContainer
          style={{ width: '370px', height: '36px', marginBottom: '64px' }}
          toastClassName=" min-h-0 bg-black text-white px-8 text-center h4 text-sBody rounded flex bg-opacity-80"
          position={toastType.POSITION.BOTTOM_CENTER}
          closeButton={false}
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          transition={Slide}
          pauseOnHover
          enableMultiContainer
        />
      )}
      {isAdminLogin || <CsrOnlyTutorBrazePixel currentUser={currentUser} />}
      <NoSSR>
        <TailwindWrapper>
          <ReactResizeDetector handleHeight onResize={(w, h) => setHeight(h)}>
            <Fragment>
              <div
                ref={topRef}
                className={classNames('fixed-top shadow-light-lg fixed left-0 right-0 z-50 bg-white shadow-sm')}
              >
                {!noChromeBanner && <ChromeDownLoadBanner />}

                {!noMainNav && (
                  <MainNav
                    currentUser={currentUser}
                    navId={mainNavId}
                    activeId={mainNavActiveId}
                    data={getData(currentUser?.is_teens, currentUser?.upcoming_lesson_count, currentUser?.teens_only)}
                    userNavs={userNavs}
                    accountNavs={accountNavs}
                  />
                )}
                {/* sub menu */}
                {!noSubNav && (
                  <SubNav
                    currentUser={currentUser}
                    subNavId={subNavId}
                    id={subNavId}
                    activeId={subNavActiveId}
                    data={getData(currentUser?.is_teens, currentUser?.upcoming_lesson_count, currentUser?.teens_only)}
                  />
                )}
                {isOpen && <SearchBar onSubmit={onSearchSubmit} />}
              </div>
              <div
                className={classNames(
                  classNames('no-scrollbar h-full p-0', className),
                  noContainer || 'max-w-1040 pt-120 mx-auto overflow-y-auto overflow-x-hidden px-20'
                )}
              >
                {children}
              </div>
              {isOpen && (
                <div onClick={() => closeSearchBar()} className="bg-dim fixed top-0 left-0 right-0 z-40 h-screen" />
              )}
              {footer && <TutorFooter />}

              {isAdminLogin && <AdminUserBadge />}
            </Fragment>
          </ReactResizeDetector>
        </TailwindWrapper>
      </NoSSR>
    </TutorRootContainer>
  )
}

export default NewTutorLayoutContainer

type MidMenuListPropsType = {
  data: any
}

// Referral, FAQ
const MidMenuList = ({ data }: MidMenuListPropsType) => {
  const [cookies, setCookies] = useCookies(['Office_Event'])
  const current = new Date()
  const nextDay = new Date(current.getTime() + 1 * 24 * 60 * 60 * 1000)

  const showTooltip = (item) => item.id == 'event' && cookies.Office_Event != 'visit'

  return (
    <div className="flex h-[70px] flex-row items-center gap-[16px]">
      {data?.map((item, i) => (
        <div key={i} className="relative">
          <LinkLocale href={item.href}>
            <div
              onClick={() => {
                if (item.id == 'event')
                  setCookies('Office_Event', 'visit', { domain: COOKIE_DOMAIN, path: '/', expires: nextDay })
              }}
              className={classNames(
                'nav-link relative flex cursor-pointer flex-col items-center py-8 text-gray-700 hover:text-purple-500'
              )}
            >
              <Span className={item.clx + ' font-medium'} size={14}>
                {item.label}
              </Span>
            </div>
          </LinkLocale>
          {showTooltip(item) && (
            <div className="absolute left-[-100%]">
              <div className="chat-bubble-top danger absolute left-[-20px] top-[5px] w-[160px] rounded-[5px] bg-red-500 px-[16px] py-[7px] text-center text-[12px] font-medium text-white">
                Earn & Redeem Points
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

type RingleMenuBodyPropsType = {
  userNavs: any
}

const RingleMenuBody = ({ userNavs }: RingleMenuBodyPropsType) => {
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()
  const logoutTutor = useLogoutTutor()
  const userInfos = [
    {
      title: 'My Stars',
      value: (
        <LinkLocale href={urls.tutor.portal.performance.status}>
          <div className="flex cursor-pointer items-center justify-between">
            {currentUser?.is_golden_star ? (
              <div>
                <Badge warning>
                  <div className="flex flex-row items-center">
                    <img src={ICON_STAR_WHITE} />
                    Golden Star
                  </div>
                </Badge>
              </div>
            ) : (
              <div>
                <Stars stars={currentUser.stars} clx="flex" />
              </div>
            )}
            <div className="ml-[8px]">
              <img src={ICON_CHEVRON_RIGHT_GRAY} />
            </div>
          </div>
        </LinkLocale>
      ),
    },
    {
      title: 'Level',
      value: currentUser?.level,
    },
    {
      title: 'Total Lessons',
      value: currentUser?.past_lesson_count,
    },
    {
      title: 'Member since',
      value: <DateComponent date={currentUser?.tutor_info?.created_at} format="MMM dd, yyyy" locale="en" />,
    },
  ]

  return (
    <div className="py-[16px]">
      <div className="truncate px-[16px] font-bold">Hello, {currentUser.first_name}</div>
      <div className="text-h6 mb-[16px] truncate px-[16px] text-gray-700">{currentUser.email}</div>
      <div className="border-t border-b bg-gray-50 p-[16px]">
        {userInfos.map((item, i) => {
          return (
            <div key={`${i}_user_info`} className="text-sBody flex flex-row">
              <div className="text-h6 mr-[6px] text-gray-500">{item.title}</div>
              <div className="text-h6 flex-1 font-medium text-gray-700">{item.value}</div>
            </div>
          )
        })}
      </div>
      <div className="flex flex-wrap px-[16px] py-[16px]">
        {userNavs
          .filter((e) => e.floor == 1)
          .map((item, i) => (
            <LinkLocale
              key={i}
              className={classNames('text-h5 relative mx-0 mt-0 min-w-full px-0 py-4 font-medium text-black')}
              href={item.href}
            >
              <div className="flex items-center">{item.label}</div>
            </LinkLocale>
          ))}
      </div>
      <hr />
      <div className="flex flex-wrap px-[16px] py-[16px]">
        {userNavs
          .filter((e) => e.floor == 2)
          .map((item, i) => (
            <LinkLocale
              key={i}
              className={classNames('text-h5 relative mx-0 mt-0 min-w-full px-0 py-4 font-medium text-black')}
              href={item.href}
            >
              <div className="flex items-center">{item.label}</div>
            </LinkLocale>
          ))}
      </div>
      <hr className="mb-16" />
      {/* 로그아웃 */}
      <a
        onClick={logoutTutor}
        className="dropdown-item text-h5 mx-0 mt-4 cursor-pointer px-16 font-medium hover:text-purple-500"
      >
        Log out
      </a>
    </div>
  )
}

type TabletMenuPropsType = {
  leftMenus: any
  rightMenus: any
}

const TabletMenu = ({ leftMenus, rightMenus }: TabletMenuPropsType) => {
  const { t } = useTranslation()

  const logoutTutor = useLogoutTutor()
  return (
    <li className="nav-item dropdown z-9999 absolute flex items-center" style={{ top: 32, right: 0 }}>
      <div
        style={{
          width: 190,
          maxHeight: 'calc(100vh - 140px)',
          overflow: 'auto',
          boxShadow: '0px 1px 3px 0 rgba(22, 28, 45, 0.12)',
        }}
        className="py-lg-4 rounded-lg border border-gray-200 bg-white p-16 text-left"
      >
        {/* 태블릿 뷰 - 좌측 포털메뉴 */}
        <div className="flex flex-wrap">
          {leftMenus
            .filter((e) => !e.none)
            .map((item, i) => (
              <LinkLocale
                key={i}
                className={classNames('dropdown-item text-h5 mx-0 mt-0 mb-4 min-w-full px-0 text-black')}
                href={item.href}
              >
                {item.label}
              </LinkLocale>
            ))}
        </div>
        <hr className="my-16" />
        <div className="flex flex-wrap">
          {rightMenus
            .filter((e) => !e.none)
            .map((item, i) => (
              <LinkLocale
                key={i}
                className={classNames('dropdown-item text-h5 mx-0 mt-0 mb-4 min-w-full px-0 text-black')}
                href={item.href}
              >
                {item.label}
              </LinkLocale>
            ))}
        </div>
        <hr className="my-16" />
        <a onClick={logoutTutor} className="dropdown-item text-h5 mx-0 mt-4 cursor-pointer px-0 hover:text-purple-500">
          {t(s_menu.mypage.sign_out)}
        </a>
      </div>
    </li>
  )
}

export const ChromeDownLoadBanner = () => {
  const [cookies, setCookies] = useCookies(['chrome_download_banner'])

  const { name, isBrowserES2016 } = useGetBrowserNameVer()
  const showChromeRecommendBanner =
    cookies.chrome_download_banner !== 'noshow' && name !== 'Chrome' && isBrowserES2016()
  const showNotSupportedBanner = !isBrowserES2016()

  const expiringForSevenDay = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)

  return (
    <>
      {/* 구형브라우저 : 빨강 */}
      {/* 크롬 외 && 신형브라우저 : 노랑 */}
      {showChromeRecommendBanner && (
        <div className="hidden min-h-[50px] items-center justify-center bg-yellow-500 bg-opacity-20 sm:flex">
          <div className="max-w-1040 flex min-h-[50px] w-full items-center justify-between px-20">
            <div className="flex flex-row">
              <img className="mr-8" src={ICON_RED_ALERT_TRIANGLE} alt={'warning'} />
              <div className="text-14 mr-12">
                We highly recommend using the latest version of Chrome for the best experience on Ringle.
              </div>
              <a target="_blank" href={'https://www.google.co.kr/chrome/'} rel="noreferrer">
                <div className="text-12 rounded-full bg-black px-12 py-3 font-medium text-white">Download Chrome</div>
              </a>
            </div>
            <div
              className="flex cursor-pointer flex-row"
              onClick={() => {
                setCookies('chrome_download_banner', 'noshow', {
                  domain: COOKIE_DOMAIN,
                  path: '/',
                  expires: expiringForSevenDay,
                })
              }}
            >
              <div className="text-12 mr-12 text-gray-500">Remind me in a week</div>
              <img className=" w-[20px]" src={ICON_X_GRAY} alt={'close'} />
            </div>
          </div>
        </div>
      )}
      {showNotSupportedBanner && (
        <div className="flex min-h-[50px] items-center justify-center bg-red-50">
          <div className="max-w-1040 flex min-h-[50px] w-full items-center justify-between px-20 py-[4px]">
            <div className="flex flex-row">
              <img className="mr-8" src={ICON_RED_ALERT_TRIANGLE} alt={'warning'} />
              <div className="text-14 mr-12">
                Your browser is not supported. Update to the latest version of Chrome to conduct Ringle lessons.
              </div>
              <a target="_blank" href={'https://www.google.co.kr/chrome/'} rel="noreferrer">
                <div className="text-12 rounded-full bg-black px-12 py-3 font-medium text-white">Download Chrome</div>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
