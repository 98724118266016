import classNames from 'classnames'
import { ICON_STAR_SMALL_PRIMARY } from '../../modules/svg/svg'

const Stars = ({ stars, clx }: { stars: any; clx: any }) => {
  const MAX_STAR = 7
  if (stars >= MAX_STAR) {
    stars = MAX_STAR
  }
  const full = Math.floor(stars)

  return (
    <>
      <div className={classNames(clx, 'text-purple-500')}>
        {Array(full)
          .fill(0)
          .map((_, i) => (
            <img key={`full-${i}`} src={ICON_STAR_SMALL_PRIMARY} width={18} height={18} />
          ))}
      </div>
    </>
  )
}

export default Stars
