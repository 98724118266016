// @ts-strict-ignore
import BodyClassName from 'react-body-classname'
import BasicHead from '../../components/common/BasicHead'

const TutorRootContainer = (props) => {
  const { ogData } = props

  return (
    <>
      <BodyClassName className="newTheme text-black" />
      <BasicHead ogData={ogData} />
      {props.children}
    </>
  )
}

export default TutorRootContainer
