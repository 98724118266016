// @ts-strict-ignore
import classNames from 'classnames'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import Parser from 'html-react-parser'
import { useState } from 'react'
import { useSidePopup } from '../../../../components/basic/popup/SidePopup'
import Badge from '../../../../components/common/Badge'
import { useAnnouncement, useNotification } from '../../../../modules/apis/tutor/notification'
import { getJwtToken } from '../../../../modules/auth'
import { useCurrentUser } from '../../../../modules/context/UserContext'
import LinkLocale from '../../../../modules/i18n/LinkLocale'
import useTranslation from '../../../../modules/i18n/useTranslation'
import { ICON_SETTINGS_GRAY, ICON_X_BLACK } from '../../../../modules/svg/svg'
import urls from '../../../../modules/urls'
import { TUTOR_NOTIFICATION_IMPORTANT_BADGE_LIST } from '../../../../modules/vars'
import Button from '../../../_legacy/Button'

interface announcementDataType {
  id: number
  content: string
  title: string
  receiver_id: number
  category: string
  tags: string
  on_click_url: string
  updated_at: string
}

interface newNotificationDataType {
  id: number
  receiver_id: number
  content: string
  title: string
  category: string
  tags: string
  on_click_url: string
  updated_at: string
  map: any
  length: number
  has_read: boolean
}

interface allNotificationDataType {
  all: IType[]
  account: IType[]
  lesson: IType[]
}

type IType = {
  id: number
  receiver_id: number
  content: string
  title: string
  category: string
  tags: string
  on_click_url: string
  updated_at: string
  has_read: boolean
}
interface IData {
  id: string
  label: any
  onClick: () => void
}
enum MENU {
  NEW = 'new',
  ALL = 'all',
}

enum SUBMENU {
  ALL = 'all',
  MATCHING = 'matching',
  LESSONS = 'lesson',
  ACCOUNT = 'account',
  EVENT = 'event',
}

enum STRING {
  TEENS = 'Teens',
}

enum SUBMENUNUM {
  ALL = 0,
  MATCHING = 1,
  LESSONS = 2,
  ACCOUNT = 3,
  EVENT = 4,
}

const NotificationPopupBody = () => {
  const sidePopup = useSidePopup()
  const { currentUser } = useCurrentUser()
  const { t, locale } = useTranslation()

  /**
   * 태그 데이터 자체가 서버에서 Parsing 되지 않은 상태에서 String으로 받아오고 있습니다.
   * Parsing하고 난 데이터는 배열로 저장되게 됩니다.
   * "Teens"라는 값을 가지는 배열값을 null처리 하여 뱃지 형태로 보여줍니다.
   * null로 변환하지 않은 상태에서는 "Teens"라는 값이 있는지 여부를 확인하여
   * 뱃지 우측에 파란색 Teens 표시를 해주게 됩니다.
   * @param tags string
   * @param isFiltered
   * @returns
   */
  const getParsedTags = (tags: string, isFiltered: boolean) => {
    const parse = Parser(tags)
      .toString()
      .replaceAll('"', '')
      .replaceAll(']', '')
      .replaceAll('[', '')
      .replaceAll('\\', '')
      .replaceAll(' ', '')

    const array = parse.split(',')

    const returnArray = array.map((item) => {
      if (item == STRING.TEENS) {
        return null
      } else {
        return item
      }
    })

    if (isFiltered) {
      return returnArray
    } else {
      return array
    }
  }

  /**
   * 최초의 시작하는 배열값을 만들어줍니다. 올 탭 하단에는 All, Matching, Lessons, Account, Event
   * 등의 탭들이 존재하게 되고, 각 탭들에 해당하는 See more 버튼을 구현하기 위해서,
   * 시작 값이 20이고, 탭 길이 만큼의 갯수를 갖는 배열을 생성합니다.
   * @returns [ 20, 20, 20, 20, 20]
   */
  const getInitialArray = () => {
    const res = []
    Object.keys(allTabData).map((key) => {
      res.push(20)
    })
    return res
  }

  // 노티피케이션 팝업 상단의 공지사항에 해당하는 부분의 데이터를 호출하고 저장합니다.
  const announcementData = useAnnouncement<announcementDataType>(getJwtToken())
  const announcementExpireDate = announcementData?.data?.updated_at
    ? format(utcToZonedTime(new Date(announcementData.data.updated_at), currentUser?.timezone || 'UTC'), 'MMMM dd')
    : ''

  // New, All 메뉴에 대한 상태를 저장합니다.
  const [menu, setMenu] = useState(MENU.NEW)

  // All탭의  All, Matching, Lessons, Account, Event 서브 메뉴에 대한 상태를 저장합니다.
  // 디폴트 서브 메뉴는 All메뉴 입니다.
  const [subMenu, setSubmenu] = useState(SUBMENU.ALL)

  // All탭의  All, Matching, Lessons, Account, Event 서브 메뉴에 대한 상태를 저장합니다
  // All = 0 , Matching = 1 , Lessons = 2,  Account = 3 , Event = 4
  const [subMenuNumber, setSubMenuNumber] = useState(0)

  // 노티피케이션 팝업의 New에 해당하는 부분의 데이터를 호출합니다.
  const newNotificationData = useNotification<newNotificationDataType>(getJwtToken(), 'new')

  // New 부분의 데이터를 저장합니다.
  const newTabData = newNotificationData?.data || []

  // New( n ) 에 해당하는 갯수를 저장합니다.
  const newCount = newTabData?.length

  // New 메뉴의 더보기 버튼에 대한 기준인 20의 배수를 저장합니다. 20, 40, 60, 80 ...
  const [seeMoreNewCount, setSeeMoreNewCount] = useState(20)

  // New 메뉴의 데이터를 더보기 버튼의 기준 숫자보다 크면 보여주지 않고 작은 경우에만 보여줍니다.
  // 20인 경우 20개를 보여주고 40인 경우 40개를 보여줍니다.
  interface INewNOtiList {
    category: string
    content: string
    has_read: boolean
    id: number
    on_click_url: string
    receiver_id: number
    tags: string
    title: string
    updated_at: string
  }
  const newNotiList: INewNOtiList[] = newTabData
    .map((data, i) => {
      if (i >= seeMoreNewCount) {
        return null
      } else {
        return data
      }
    })
    .filter((e) => e != null)

  // 노티피케이션 팝업의 All에 해당하는 부분의 데이터를 호출합니다.
  const allNotificationData = useNotification<allNotificationDataType>(getJwtToken(), 'all')

  // All 부분의 데이터를 저장합니다.
  const allTabData = allNotificationData?.data || []

  // All 메뉴의 더보기 버튼에 대한 기준인 20의 배수를 각각 배열에 저장합니다. [ 20, 40, 60, 80, 20 ]
  const [seeMoreAllCount, setSeeMoreAllCount] = useState(getInitialArray())

  // All 메뉴의 데이터를 더보기 버튼의 기준 숫자보다 크면 보여주지 않고 작은 경우에만 보여줍니다.
  // 20인 경우 20개를 보여주고 40인 경우 40개를 보여줍니다.
  // 현재 선택한 서브메뉴에 따라 보여주게 됩니다.
  interface IAllNotiList {
    category: string
    content: string
    has_read: boolean
    id: number
    on_click_url: string
    receiver_id: number
    tags: string
    title: string
    updated_at: string
  }

  const allNotiList: IAllNotiList[] = allTabData[`${subMenu}`]
    ?.map((data, i) => {
      if (i >= seeMoreAllCount[subMenuNumber]) {
        return null
      } else {
        return data
      }
    })
    .filter((e) => e != null)

  // 메뉴리스트를 저장합니다. 각 클릭에 대한 동작을 저장합니다.
  const menuList = [
    {
      label: <div>New({newCount})</div>,
      id: MENU.NEW,
      onClick: () => setMenu(MENU.NEW),
    },
    {
      label: <div>All</div>,
      id: MENU.ALL,
      onClick: () => setMenu(MENU.ALL),
    },
  ]

  // 서브메뉴 리스트를 저장합니다. 각 서브메뉴에 따라 String값과 Number값을 각각 저장합니다.
  const subMenuList = [
    {
      label: <div>All</div>,
      id: SUBMENU.ALL,
      onClick: () => {
        setSubmenu(SUBMENU.ALL)
        setSubMenuNumber(SUBMENUNUM.ALL)
      },
    },
    {
      label: <div>Matching</div>,
      id: SUBMENU.MATCHING,
      onClick: () => {
        setSubmenu(SUBMENU.MATCHING)
        setSubMenuNumber(SUBMENUNUM.MATCHING)
      },
    },
    {
      label: <div>Lessons</div>,
      id: SUBMENU.LESSONS,
      onClick: () => {
        setSubmenu(SUBMENU.LESSONS)
        setSubMenuNumber(SUBMENUNUM.LESSONS)
      },
    },
    {
      label: <div>Account</div>,
      id: SUBMENU.ACCOUNT,
      onClick: () => {
        setSubmenu(SUBMENU.ACCOUNT)
        setSubMenuNumber(SUBMENUNUM.ACCOUNT)
      },
    },
    {
      label: <div>Event</div>,
      id: SUBMENU.EVENT,
      onClick: () => {
        setSubmenu(SUBMENU.EVENT)
        setSubMenuNumber(SUBMENUNUM.EVENT)
      },
    },
  ]

  // 선택한 메뉴에 따라 컬러가 달라집니다.
  const selectedMenu = (data: IData) =>
    data.id == menu ? 'border-purple-500 text-purple-500' : 'border-transparent text-gray-500'
  //서브 메뉴를 가지고 있는지 여부에 따라 달라집니다. All 메뉴만 서브메뉴를 가지고 있습니다.
  const isSubmenu = menu == MENU.ALL ? 'mb-[50px]' : 'mb-0'

  /**
   * 중요 리스트에 해당하는 뱃지에 대한 String값과 일치하면 true, 아니면 false를 리턴합니다.
   * @param label 뱃지에 해당하는 tags 데이터의 배열값을 입력받습니다.
   * @returns true / false
   */
  const isImportantBadge = (label: string) => {
    if (label) {
      TUTOR_NOTIFICATION_IMPORTANT_BADGE_LIST.map((data) => {
        if (data.title == label) {
          return true
        } else {
          return false
        }
      })
    } else {
      return false
    }
  }
  /**
   * New 메뉴의 이전 알림 데이터의 날짜와 이번 알림 데이터의 날짜의 형태가 동일한지 확인합니다.
   * @param order 몇번째 데이터인지 알려줍니다.
   * @returns true / false
   */
  const isSameDateNew = (order) => {
    const date1 = newTabData[order]?.updated_at
    const date2 = newTabData[order - 1]?.updated_at

    if (!date1 || !date2) {
      return false
    }

    const formattedDate1 = format(utcToZonedTime(new Date(date1), currentUser?.timezone || 'UTC'), 'MMMM dd, yyyy')
    const formattedDate2 = format(utcToZonedTime(new Date(date2), currentUser?.timezone || 'UTC'), 'MMMM dd, yyyy')

    return formattedDate1 !== formattedDate2
  }

  /**
   * All 메뉴의 선택한 서브메뉴에 대한 이전 알림 데이터의 날짜와 이번 알림 데이터의 날짜의 형태가 동일한지 확인합니다.
   * @param order 몇번째 데이터인지 알려줍니다.
   * @returns true / false
   */

  const isSameDateAll = (order) => {
    const date1 = allTabData[`${subMenu}`][order]?.updated_at
    const date2 = allTabData[`${subMenu}`][order - 1]?.updated_at

    if (!date1 || !date2) {
      return false
    }

    const formattedDate1 = format(utcToZonedTime(new Date(date1), currentUser?.timezone || 'UTC'), 'MMMM dd, yyyy')
    const formattedDate2 = format(utcToZonedTime(new Date(date2), currentUser?.timezone || 'UTC'), 'MMMM dd, yyyy')

    return formattedDate1 !== formattedDate2
  }

  /**
   * All 메뉴의 선택한 서브메뉴의 더보기 버튼에 대한 동작을 실행하는 함수입니다.
   * 선택한 서브메뉴에 해당하는 더보기 버튼의 값에 대해서 +20 해줍니다.
   * ex : [20,20,20,20] => [20,40,20,20]
   */
  const handleSeeMoreButton = () => {
    const temp = [...seeMoreAllCount]
    temp[subMenuNumber] += 20
    setSeeMoreAllCount(temp)
  }
  /**
   * 알림 하나에 해당하는 컴포넌트 입니다.
   * @param param0 각 탭에 해당하는 데이터를 넘겨 받습니다.
   * @returns
   */
  const NotificationBox = ({ data }) => {
    // 중요 뱃지이면, 빨간색 아닌 경우 회색으로 보여지게 됩니다.
    const isImportant = (item) =>
      isImportantBadge(item)
        ? {
            dangerLight: true,
          }
        : {
            disabled: true,
          }
    return (
      <div>
        <div
          className={classNames(
            { 'bg-gray-50': !data.has_read },
            { 'bg-white': data.has_read },
            'py-[12px] pl-[20px] pr-[16px]'
          )}
        >
          <div className="flex flex-row items-start">
            <div className="col-2 text-[12px] text-gray-500">
              {format(utcToZonedTime(new Date(data?.updated_at), currentUser?.timezone || 'UTC'), 'hh:mm a')}
            </div>
            <div className="flex flex-col">
              <div className="mb-[4px] flex items-center">
                {getParsedTags(data.tags, true).map((data, i) => {
                  if (data)
                    return (
                      <Badge key={i} flex itemsCenter mr4 {...isImportant(data)}>
                        {data}
                      </Badge>
                    )
                })}
                {getParsedTags(data.tags, false).includes(STRING.TEENS) && (
                  <div className="ml-[8px] text-[12px] font-medium text-blue-500">Teens</div>
                )}
              </div>
              <div className="font-medium">{data.title}</div>
              <div className="mt-[4px] text-[14px] text-gray-700">{data.content}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="border-b-1 fixed top-[0px] z-10 flex h-[56px] w-full justify-between bg-white py-[16px] px-[20px]">
        <div className="font-medium">Notification</div>
        <div className="flex flex-row items-center">
          <LinkLocale href={urls.tutor.portal.mypage.noti}>
            <img src={ICON_SETTINGS_GRAY} className="mr-[20px] w-[20px]" />
          </LinkLocale>
          <div onClick={() => sidePopup.close('noti-popup')}>
            <img src={ICON_X_BLACK} className="w-[20px]" />
          </div>
        </div>
      </div>
      {/* 공지사항 */}
      <div className="relative mt-[55px] flex flex-col">
        {announcementData?.data && (
          <div className="border-b-1 bg-gray-50 py-[16px] px-[20px]">
            <div className="flex justify-between">
              <div className="text-[12px] font-medium text-purple-500">Announcement</div>
              <div className="text-[12px] font-medium text-gray-500">{announcementExpireDate}</div>
            </div>
            <div className="mt-[8px] font-medium">{announcementData?.data?.title}</div>

            <div className="mt-[4px] text-[14px] text-gray-700">{announcementData?.data?.content}</div>
          </div>
        )}
        {/* 메뉴 */}
        <div
          className={classNames('sticky top-[55px] z-[10] flex h-[53px] flex-col justify-between bg-white', isSubmenu)}
        >
          <div className="border-b-1 flex flex-row items-center bg-white pl-[8px]">
            {menuList.map((data, i) => {
              return (
                <div
                  key={i}
                  className={classNames(
                    'mx-[12px] cursor-pointer border-b-2 pt-[16px] pb-[13px] text-[15px]',
                    selectedMenu(data)
                  )}
                  onClick={() => setMenu(data.id)}
                >
                  {data.label}
                </div>
              )
            })}
          </div>
          {menu == MENU.ALL && (
            <div className="border-b-1 flex flex-row bg-white py-[12px] pl-[20px]">
              {/* 서브메뉴 */}
              {subMenuList.map((item, i) => {
                if (subMenu == item.id) {
                  return (
                    <div key={i} className="mr-[8px]">
                      <div className="border-1 cursor-pointer rounded-full border-purple-500 bg-purple-50 px-[12px] py-[3px] text-[12px] font-medium text-purple-500">
                        {item.label}
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div key={i} className="mr-[8px]">
                      <div
                        onClick={() => item.onClick()}
                        className="border-1 cursor-pointer rounded-full border-gray-500 px-[12px] py-[3px] text-[12px] font-medium text-gray-500"
                      >
                        {item.label}
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          )}
        </div>
        {/* New */}
        {/* New */}
        {menu == MENU.NEW && (
          <div className="relative">
            {!newTabData || newTabData?.length == 0 ? (
              <div className="flex flex-col justify-center px-[24px] text-[14px]">
                <div className="mt-[64px] text-center">You have no upcoming lessons.</div>
                <div className="text-center">Update your schedule to open slots!</div>
                <Button mt16 md outlined href={`/${locale}${urls.tutor.portal.schedule.index}`}>
                  Go to Schedule
                </Button>
              </div>
            ) : (
              newNotiList.map((item: newNotificationDataType, i: number) => {
                if (isSameDateNew(i) || i == 0) {
                  return (
                    <>
                      <div key={i} className="sticky top-[104px] h-[40px] bg-white">
                        <div className="border-b-1 pt-[12px] pb-[5px] pl-[20px] text-[14px] font-medium">
                          {format(
                            utcToZonedTime(new Date(item?.updated_at), currentUser?.timezone || 'UTC'),
                            'MMMM dd, yyyy'
                          )}
                        </div>
                      </div>
                      <NotificationBox data={item} />
                    </>
                  )
                } else {
                  return (
                    <div key={i}>
                      <NotificationBox data={item} />
                    </div>
                  )
                }
              })
            )}
            <div className="mb-[80px] flex justify-center py-[16px]">
              {/* 20개 이상인 경우에만 더보기 버튼을 보여줍니다. */}
              {newTabData.length > 20 &&
                //보여줄게 더 남은 경우에만 더보기 버튼을 보여줍니다.
                (seeMoreNewCount > newTabData.length ? (
                  <></>
                ) : (
                  <Button lg outlined onClick={() => setSeeMoreNewCount((prev) => prev + 20)}>
                    See more
                  </Button>
                ))}
            </div>
          </div>
        )}

        {/* All */}
        {/* All */}
        {menu == MENU.ALL && (
          <div className="relative">
            {
              //알림이 없는 경우
              !allNotiList || allNotiList?.length == 0 ? (
                <div className="flex flex-col justify-center px-[24px] text-[14px]">
                  <div className="mt-[64px] text-center">You have no upcoming lessons.</div>
                  <div className="text-center">Update your schedule to open slots!</div>
                </div>
              ) : (
                //알림이 있는 경우
                allNotiList.map((item: newNotificationDataType, i: number) => {
                  //이전 탭과 같은 날짜 이거나 최상단의 첫번째 탭인경우 날짜 부분과 탭부분을 모두 보여줍니다.
                  if (isSameDateAll(i) || i == 0) {
                    return (
                      <>
                        <div className="sticky top-[153px] h-[40px] bg-white">
                          <div className="border-b-1 pt-[12px] pb-[5px] pl-[20px] text-[14px] font-medium">
                            {format(
                              utcToZonedTime(new Date(item?.updated_at), currentUser?.timezone || 'UTC'),
                              'MMMM dd, yyyy'
                            )}
                          </div>
                        </div>
                        <NotificationBox data={item} />
                      </>
                    )
                    //이전 탭과 다른 날짜 이면서 첫번째 탭도 아닌 경우에 탭부분만 보여줍니다.
                  } else {
                    return (
                      <div key={i}>
                        <NotificationBox data={item} />
                      </div>
                    )
                  }
                })
              )
            }
            {/* 20개 이상인 경우에만 더보기 버튼을 보여줍니다. */}
            <div className="mb-[80px] flex justify-center py-[16px]">
              {allTabData[`${subMenu}`]?.length > 20 &&
                //보여줄게 더 남은 경우에만 더보기 버튼을 보여줍니다.
                (seeMoreAllCount[subMenuNumber] > allTabData[`${subMenu}`]?.length ? (
                  <></>
                ) : allTabData[`${subMenu}`] ? (
                  <Button lg outlined onClick={() => handleSeeMoreButton()}>
                    See more
                  </Button>
                ) : (
                  <></>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default NotificationPopupBody
