import dynamic from 'next/dynamic'

interface NoSSRProps {
  children: React.ReactNode
}

const NoSSR = ({ children }: NoSSRProps) => {
  return <>{children}</>
}

export default dynamic(() => Promise.resolve(NoSSR), {
  ssr: false,
})
